<template>
  <Navbar :lang="lang" ref="nav" :key="key"></Navbar>
  <router-view :lang="lang" key="key" />
  <Footer></Footer>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'


export default {
  components: {
    Navbar,
    Footer
  },
  created(){
	if(document.cookie.indexOf('locale=') < 0 ){
		document.cookie = "locale=sk;SameSite=Lax"
	}
  },
   data() {
      return {
        key: 0,
	locale: 0,
      };
    },
    methods: {
      forceRerender() {
        this.key += 1;
      }
    },
     computed:{
        lang() {
		if (this.locale === 0){
			let match = document.cookie.match(new RegExp('(^| )locale=([^;]+)'));
			if (match[2]){
                                return match[2];
                        }
                }
		return this.locale;
                }

  },
}

</script>
<style>
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
