<template>
  <Home></Home>
</template>

<script>
import Home from '@/components/Home.vue'


export default {
  components: {
	Home
  }
}

</script>
<style>
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
~            
