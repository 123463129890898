import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../views/Contacts.vue')
  },
  {
    path: "/rdp",
    beforeEnter() {
      window.location.href = "https://rdp.xalan.sk";
    }	    
 },
 {
    path: "/webmail",
    beforeEnter() {
    window.location.href = "https://webmail.xalan.sk";
 }
 },
 {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },


]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
