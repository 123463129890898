<template>
  <div class="p-5 mb-4 bg-light rounded-3"><!----><!----><h1 class="fst-italic fw-lighter text-muted">"{{slogan[this.$root.lang]}}"</h1></div>
  <div class="row gx-0 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 justify-content-center row-equal p-5 mb-4 bg-light rounded-3">
    <div class="card m-1" style="max-width: 30rem;" v-for="item in items[this.$root.lang]" :key="item.name">
	<div class="card-body text-center p-0" >
	<img :src="item.icon" alt="Card Image" class="card-img" width="200" height="250">
	<h4 class="card-title">{{item.title}}</h4><h6 class="card-subtitle text-muted mb-2">{{item.sub_title}}</h6><p class="pb-4 card-text">{{item.text}} </p>
	</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data(){
	return {
		items:{
		"en":[
			{title:'Integration Architecture',sub_title:'System Architecture and Cloud', text:'Integrating 3-rd party components to work well together.', icon:'/img/data-integration.webp'},
			{title:'SECURITY',sub_title:'Penetration Testing',text:'Using Acunetix to find web application vulnerabilities.',icon:'/img/security.webp'},
			{title:'Applications development', sub_title:'Information systems',text:'Using Bootstrap, VUE js, HTML5, Laravel for fast application development.',icon:'/img/development.webp'},
			{title:'IoT sensors',sub_title:'LoRa, ZegBee, NB-IoT, Sigfox',text:'Development and integration of IoT sensors in our solutions.', icon:'/img/iot.webp'},
			{title:'DEVOPS',sub_title:'CI/CD',text:'Agile software development needs teams to work together.', icon:'/img/devops.webp'}
			]
		,
		"sk":[
                        {title:'Procesná integrácia systémov',sub_title:'Systémová Architectúra, Cloud', text:'Zabezpečujeme konzultácie, kompletný návrh architektúry a integráciu komponentov externých dodávateľov.', icon:'/img/data-integration.webp'},
                        {title:'Aplikčná Bezpečnosť',sub_title:'Penetračné Testovanie',text:'Pravidelným skenovaním pomocou nástroja Acunetix odhaľujeme potenciálne zraniteľnosti.',icon:'/img/security.webp'},
                        {title:'Vývoj aplikacii', sub_title:'Informačné systémy',text:'Rýchly vývoj aplikácií pomocou  Bootstrap, VUE js, HTML5, Laravel.',icon:'/img/development.webp'},
                        {title:'IoT senzory',sub_title:'LoRa, ZegBee, NB-IoT, Sigfox',text:'Vývoj a integrácia IoT senzorov v našich riešeniach.', icon:'/img/iot.webp'},
                        {title:'DEVOPS',sub_title:'CI/CD',text:'Agilný vývoj softvéru vyžaduje automatizáciu a kooperáciu vývojového a prevádzkového tímu.', icon:'/img/devops.webp'}
                        ]
		},
		slogan:{"en":"It's all about to bring Your vision to market on time.",
			"sk":"Spolu dostaneme Vašu víziu na trh včas."

		},
		locale: 0
       }
  },
  created () {
            document.title = "Home | Xalan s.r.o.";
  },
}
</script>
