import { createApp } from 'vue'
//import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap-icons/font/bootstrap-icons.css';
//import VueCookies from 'vue3-cookies'


import 'bootstrap/dist/js/bootstrap.js';
//createApp(App).use(router,VueMeta).mount('#app')
createApp(App).use(router).mount('#app')
